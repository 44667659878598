import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import IncidencesFinancieres from "./components/IncidencesFinancieres";
import { addSpace, rescueHtml } from "./components/Tools";
import {NotificationManager} from 'react-notifications';
import { ReactComponent as Trash } from './Img/icon-trash.svg';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Add } from './Img/icon-add.svg';
import Dialog from "./components/Dialog";

const FichesProjets = (props) =>
{
    let history = useHistory();
    const [idSelected, setIdSelected] = useState(null)
    const [listeProjets, setListeProjets] = useState([])
    const [aff, setAff] = useState(false)
    const [annees, setAnnees] = useState([])
    const [ongletListeProjets, setOngletListeProjets] = useState(true)
    

    const purple = '#6571FE'
    const green = '#59B44A'
    
    const [suppressAll, setSuppressAll] = useState(false);
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    
    async function callbackDialog(ret) {
        if (ret === true) {
            if (idSelected === -1) {
                await listeProjets.forEach(async projet => {
                    await suppressProjet(projet[0]);
                });
                await setIdSelected(null);
            }
            else {
                await suppressProjet(idSelected);
                await setIdSelected(null);
            }
        }
    }
    

    async function create()
    {
        if (props.user.id_budget && props.user.id_budget !== null) {
            let numero = 0
            if (listeProjets.length !== 0) {
                listeProjets.forEach(projet => {
                    numero = projet[2]
                });
            }
            numero++;
            const params = {
                'creationFicheProjet': true,
                'id_budget': props.user.id_budget,
                'numero_projet': numero,
                'annees': annees
            }
            await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify(params))
            .then((result) => {
                if (result.data['status'] === 1) {
                    console.log(result.data.id_projet)
                    NotificationManager.success(result.data['status_message'], '', 700);
                    setTimeout(() => window.location = window.location.origin + '/saisie/prospective/ficheprojet/'+result.data.id_projet, 900);
                }
                else {
                    NotificationManager.warning(result.data['status_message'], '', 2000);
                }
            })
        }
    }

    async function suppressProjet(id)
    {
        const params = {
            'suppressProjet': true,
            'id': id
        }
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify(params))
        .then((result) => {
            if (result.data['status'] === 1) {
                NotificationManager.success(result.data['status_message'], '', 700);
                setTimeout(() => window.location = window.location.href, 900);
            }
            else {
                NotificationManager.warning(result.data['status_message'], '', 2000);
            }
        })
    }


    async function mount() {
        const params = {
            'getFichesProjets': true,
            'id_budget': props.user.id_budget
        }
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify(params))
        .then((result) => {
            setListeProjets(result.data)
        })


        // Recuperation des annees de prospective
        let prosp_an_deb = ''
        let prosp_an_fin = ''
        await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+props.user.id_budget)
        .then((result) => {
            result.data.forEach(element => {
                if (element[2] === 'prosp_an_deb') {
                    prosp_an_deb = parseInt(element[3])
                }
                if (element[2] === 'prosp_an_fin') {
                    prosp_an_fin = parseInt(element[3])
                }
            });
        }).catch((err) => {console.log(err)})
        
        if (prosp_an_deb === '' || prosp_an_fin === '')
        {
            await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
            .then(async function (res) {
                if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                    if (prosp_an_deb === '') {
                        prosp_an_deb = parseInt(res.data.prosp_an_deb)
                    }
                    if (prosp_an_fin === '') {
                        prosp_an_fin = parseInt(res.data.prosp_an_fin)
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        var liste_an = []
        var i = 0
        while (prosp_an_deb <= prosp_an_fin)
        {
            liste_an[i] = parseInt(prosp_an_deb)
            prosp_an_deb++;
            i++;
        }
        await setAnnees(liste_an);
    }

    
    // Récuperation de la liste des fiches projets
    useEffect(() => {
        if (props.user === null) {
            props.getUser()
        }
        else {
            mount();
        }
    }, [props.user])


    // Fonction qui permet de cocher ou décocher les hypotheses choisies.
    // A chaque changement de status, cela demande au serveur s'il peut changer le status de l'hypothese choisie, si le retour est positif,
    // alors ca change la case en checked.
    async function hypotheseChecked(id, h, status)
    {
        var tmp = listeProjets
        await tmp.forEach(async (projet, i) => {
            if (projet[0] === id) {
                tmp[i][h] = status
                const params = {
                    'updateProjet': true,
                    'projet': tmp[i]
                }
                await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify(params))
                .then(async (result) => {
                    if (result.data['status'] === 1) {
                        await setListeProjets(tmp)
                        await setAff(!aff)
                    }
                })
            }
        });
    }

	return (
        <div>
            <Access needConnection={true} type_user={[0]}>
                <Navbar user={props.user} />

                <Dialog
                    setModalVisible={setDialogModalVisible}
                    modalVisible={dialogModalVisible}
                    callbackDialog={callbackDialog}
                    message={"Confirmez la suppression :"}
                />

                <Dialog
                    setModalVisible={setSuppressAll}
                    modalVisible={suppressAll}
                    callbackDialog={callbackDialog}
                    message={"Voulez vous supprimer toutes les fiches projet ?"}
                />



<div style={{display: 'flex'}}>
                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <div style={{flex: 1, display: 'flex'}}>
                            <p>Prospective</p>
                            <p style={{marginLeft: 5}}>\</p>
                            <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Fiches projets</p>
                        </div>
                        <div style={{flex: 1}}>
                            {props.user?
                            <p style={{flex: 1, textAlign: 'center'}}>{props.user.nom_budget}</p>
                            :null}
                        </div>
                        <div style={{flex: 1}}></div>
                    </div>
                </div>

                <div style={{flex: 1, margin: 20, display: 'flex'}}>
                    <div style={{flex: 1}}></div>
                    <button
                        className='shadow-md p-2 rounded-md'
                        style={ongletListeProjets?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                        onClick={() => {
                            setOngletListeProjets(true)
                        }}
                    >
                        Liste des projets
                    </button>

                    <button
                        className='shadow-md p-2 rounded-md'
                        style={!ongletListeProjets?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                        onClick={() => {
                            setOngletListeProjets(false)
                        }}
                    >
                        Incidences Financieres
                    </button>
                    <div style={{flex: 1}}></div>
                </div>


                {ongletListeProjets?
                <div style={{flex: 1, textAlign: 'center'}}>

                    <button
                        className='shadow-md rounded-md'
                        style={{backgroundColor: green, color: 'white', fontSize: 14, margin: 10}}
                        onClick={() => {
                            create()
                        }}
                    >
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}>
                            <Add width="12" />
                            <p style={{marginLeft: 5}}>Créer un projet</p>
                        </div>
                    </button>

                    <div style={{flex: 1, display: 'flex'}}>

                        <div style={{flex: 1, margin: 15}}>

                            <div style={{display: 'flex', margin: 10}}>
                                
                                <button
                                    onClick={async() => {
                                        await setIdSelected(-1)
                                        await setSuppressAll(true)
                                    }}
                                >
                                    <Trash width="50" />
                                </button>
                                
                                <p style={{flex: 0.1}}>Numéro</p>
                                <p style={{flex: 1}}>Intitulé du projet</p>
                                <p style={{flex: 0.5}}>Montant du projet</p>
                                <p style={{flex: 0.1}}>H1</p>
                                <p style={{flex: 0.1}}>H2</p>
                                <p style={{flex: 0.1}}>H3</p>
                            </div>

                            {/* Listing des fiches projets */}
                            {listeProjets.map((projet, i) =>
                            <div className='shadow-xl rounded-sm' key={i} style={{display: 'flex', margin: 10, backgroundColor: 'white'}}>
                                
                                <button
                                    onClick={async() => {
                                        await setIdSelected(projet[0])
                                        await setDialogModalVisible(true)
                                    }}
                                >
                                    <Trash width="50" />
                                </button>
                                
                                <p className='shadow-sm' style={{flex: 0.1}}>{projet[2]}</p>
                                <button
                                className='shadow-sm'
                                style={{flex: 1}}
                                onClick={() => {
                                    history.push('/saisie/prospective/ficheprojet/'+projet[0])
                                }}
                                >
                                    <p>{rescueHtml(projet[3])}</p>
                                </button>
                                <button
                                className='shadow-sm'
                                style={{flex: 0.5}}
                                onClick={() => {
                                    history.push('/saisie/prospective/ficheprojet/'+projet[0])
                                }}
                                >
                                    <p>{projet[9]?addSpace(parseFloat(projet[9]))+' €':null}</p>
                                </button>
                                <p className='shadow-sm' style={{flex: 0.1}}>{<input type="checkbox" checked={projet[24] === "0"?false:true} onChange={() => hypotheseChecked(projet[0], 24, projet[24] === "0"?"1":"0")}/>}</p>
                                <p className='shadow-sm' style={{flex: 0.1}}>{<input type="checkbox" checked={projet[25] === "0"?false:true} onChange={() => hypotheseChecked(projet[0], 25, projet[25] === "0"?"1":"0")}/>}</p>
                                <p className='shadow-sm' style={{flex: 0.1}}>{<input type="checkbox" checked={projet[26] === "0"?false:true} onChange={() => hypotheseChecked(projet[0], 26, projet[26] === "0"?"1":"0")}/>}</p>
                            </div>
                            )}
                        </div>

                    </div>
                </div>
                :null}


                {!ongletListeProjets?
                <div style={{flex: 1, textAlign: 'center', marginTop: 10, marginLeft: 30, marginRight: 30, marginBottom: 30}}>
                    {props.user !== null && annees.length > 0?
                    <IncidencesFinancieres user={props.user} annees={annees} aff={aff} />
                    :null
                    }
                </div>
                :null}

            </Access>
        </div>
	);
}

export default FichesProjets;
