import React, { useEffect, useState } from 'react';
import Access from "../components/Access";
import Navbar from "../components/Navbar";
import NavbarAdmin from "../components/NavbarAdmin";
import ModalUpdateUser from "../components/ModalUpdateUser";
import { rescueHtml } from '../components/Tools';
import Checked from '../Img/icon-checked.png';
import axios from 'axios';
import qs from 'qs';
import {NotificationManager} from 'react-notifications';


const Utilisateurs = (props) =>
{
    const [aff, setAff] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [userFocus, setUserFocus] = useState(false)
    const [boolID, setBoolID] = useState(true)
    const [boolNom, setBoolNom] = useState(false)
    const [boolPrenom, setBoolPrenom] = useState(false)
    const [boolEmail, setBoolEmail] = useState(false)
    const [boolId_organisme, setBoolId_organisme] = useState(false)
    const [boolRang, setBoolRang] = useState(false)
    const [boolCertified, setBoolCertified] = useState(false)
    const [liste, setListe] = useState([])
    const [organismes, setOrganismes] = useState([]);
    const [filtre_organisme, setFiltre_organisme] = useState(false);
    const heightObject = window.innerHeight/1.9

    const purple = '#6571FE'
    const blue = '#069BD3'

    useEffect(() => {
        if (!props.user) {
            props.getUser();
        }

        axios.post("https://app-slfinance.fr/api/organismes.php", qs.stringify({'getOrganismes': true}))
        .then((res) => {
            var tmp = [];
            for (let i = 0; i < res.data.length; i++) {
                tmp[i] = {
                    id: res.data[i].id,
                    nom: rescueHtml(res.data[i].nom)
                }
            }
            setOrganismes(tmp);
        })
        .catch((err) => {
            console.log(err)
        })

        axios.post("https://app-slfinance.fr/api/users.php", qs.stringify({'getUsers': true}))
        .then((res) => {
            console.log(res.data)
            setListe(res.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }, [props.user])


    function compareIdAsc(a, b)
    {
        return a.id - b.id;
    }

    function compareIdDes(a, b)
    {
        return b.id - a.id;
    }

    function compareNomAsc(a, b)
    {
        // Convertir en majuscules pour assurer la casse
        const nomA = a.nom?a.nom.toUpperCase():0;
        const nomB = b.nom?b.nom.toUpperCase():0;
    
        if (nomA < nomB) {
            return -1;
        }
        if (nomA > nomB) {
            return 1;
        }
        return 0;
    }

    function compareNomDes(a, b)
    {
        // Convertir en majuscules pour assurer la casse
        const nomA = a.nom?a.nom.toUpperCase():0;
        const nomB = b.nom?b.nom.toUpperCase():0;
    
        if (nomA > nomB) {
            return -1;
        }
        if (nomA < nomB) {
            return 1;
        }
        return 0;
    }

    function comparePrenomAsc(a, b)
    {
        // Convertir en majuscules pour assurer la casse
        const prenomA = a.prenom?a.prenom.toUpperCase():0;
        const prenomB = b.prenom?b.prenom.toUpperCase():0;
    
        if (prenomA < prenomB) {
            return -1;
        }
        if (prenomA > prenomB) {
            return 1;
        }
        return 0;
    }

    function comparePrenomDes(a, b)
    {
        // Convertir en majuscules pour assurer la casse
        const prenomA = a.prenom?a.prenom.toUpperCase():0;
        const prenomB = b.prenom?b.prenom.toUpperCase():0;
    
        if (prenomA > prenomB) {
            return -1;
        }
        if (prenomA < prenomB) {
            return 1;
        }
        return 0;
    }

    function compareEmailAsc(a, b)
    {
        // Convertir en majuscules pour assurer la casse
        const emailA = a.email?a.email.toUpperCase():0;
        const emailB = b.email?b.email.toUpperCase():0;
    
        if (emailA < emailB) {
            return -1;
        }
        if (emailA > emailB) {
            return 1;
        }
        return 0;
    }

    function compareEmailDes(a, b)
    {
        // Convertir en majuscules pour assurer la casse
        const emailA = a.email?a.email.toUpperCase():0;
        const emailB = b.email?b.email.toUpperCase():0;
    
        if (emailA > emailB) {
            return -1;
        }
        if (emailA < emailB) {
            return 1;
        }
        return 0;
    }

    function compareId_organismeAsc(a, b)
    {
        return a.id_organisme - b.id_organisme;
    }

    function compareId_organismeDes(a, b)
    {
        return b.id_organisme - a.id_organisme;
    }

    function compareRangAsc(a, b)
    {
        return a.id_type_users - b.id_type_users;
    }

    function compareRangDes(a, b)
    {
        return b.id_type_users - a.id_type_users;
    }

    function compareCertifiedAsc(a, b)
    {
        if (a.token && !b.token) {
            return 1;
        }
        if (!a.token && b.token) {
            return -1;
        }
        return 0;
    }

    function compareCertifiedDes(a, b)
    {
        if (a.token && !b.token) {
            return -1;
        }
        if (!a.token && b.token) {
            return 1;
        }
        return 0;
    }

	return (
        <Access needConnection={true} type_user={[1]}>
            <Navbar />
            <NavbarAdmin />
            <ModalUpdateUser
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            user={userFocus}
            who={props.user?props.user.id:null}
            />

            <div style={{display: 'flex', margin: 25}}>
                <p>Gestion \</p>
                <p style={{color: purple, marginLeft: 5, fontWeight: 'bold'}}>Utilisateurs</p>
                <div style={{display: 'flex', marginLeft: 100}}>
                    {/* <p>Filtres:</p> */}
                    <select className='shadow-md' style={{width: 250, padding: 5, marginLeft: 10}} onChange={(e) => {setFiltre_organisme(e.target.value)}}>
                        <option value=''>Tous les organismes</option>
                        {
                            organismes.map((organisme) => 
                            <option key={organisme['id']} value={organisme['id']}>{organisme['nom']}</option>
                            )
                        }
                    </select>
                </div>
            </div>


            <div className='shadow-md rounded-md' style={{margin: 50}}>
                <div style={{overflowY: 'scroll'}}>
                    <div style={{display: 'flex', backgroundColor: blue, borderTopLeftRadius: 5, borderTopRightRadius: 5, color: 'white'}}>
                        
                        <button
                        onClick={async () => {
                            if (boolID) {
                                await liste.sort(compareIdDes);
                            }
                            else {
                                await liste.sort(compareIdAsc);
                            }
                            await setBoolID(!boolID);
                            await setAff(!aff);

                        }}
                        style={{flex: 0.2, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                            <p>Id</p>
                        </button>
                        
                        <button
                        onClick={async () => {
                            if (boolNom) {
                                await liste.sort(compareNomDes);
                            }
                            else {
                                await liste.sort(compareNomAsc);
                            }
                            await setBoolNom(!boolNom);
                            await setAff(!aff);
                        }}
                        style={{flex: 0.5, alignItems: 'center', justifyContent: 'center', display: 'flex', borderLeft: '1px solid white'}}>
                            <p>Nom</p>
                        </button>
                        
                        <button
                        onClick={async () => {
                            if (boolPrenom) {
                                await liste.sort(comparePrenomDes);
                            }
                            else {
                                await liste.sort(comparePrenomAsc);
                            }
                            await setBoolPrenom(!boolPrenom);
                            await setAff(!aff);
                        }}
                        style={{flex: 0.5, alignItems: 'center', justifyContent: 'center', display: 'flex', borderLeft: '1px solid white'}}>
                            <p>Prenom</p>
                        </button>
                        
                        <button
                        onClick={async () => {
                            if (boolEmail) {
                                await liste.sort(compareEmailDes);
                            }
                            else {
                                await liste.sort(compareEmailAsc);
                            }
                            await setBoolEmail(!boolEmail);
                            await setAff(!aff);
                        }}
                        style={{flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', borderLeft: '1px solid white'}}>
                            <p>Email</p>
                        </button>
                        
                        <button
                        onClick={async () => {
                            if (boolId_organisme) {
                                await liste.sort(compareId_organismeDes);
                            }
                            else {
                                await liste.sort(compareId_organismeAsc);
                            }
                            await setBoolId_organisme(!boolId_organisme);
                            await setAff(!aff);
                        }}
                        style={{flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', borderLeft: '1px solid white'}}>
                            <p>Id organisme</p>
                        </button>
                        
                        <button
                        onClick={async () => {
                            if (boolRang) {
                                await liste.sort(compareRangDes);
                            }
                            else {
                                await liste.sort(compareRangAsc);
                            }
                            await setBoolRang(!boolRang);
                            await setAff(!aff);
                        }}
                        style={{flex: 0.2, alignItems: 'center', justifyContent: 'center', display: 'flex', borderLeft: '1px solid white'}}>
                            <p>Rang</p>
                        </button>
                        
                        <button
                        onClick={async () => {
                            if (boolCertified) {
                                await liste.sort(compareCertifiedDes);
                            }
                            else {
                                await liste.sort(compareCertifiedAsc);
                            }
                            await setBoolCertified(!boolCertified);
                            await setAff(!aff);
                        }}
                        style={{flex: 0.2, alignItems: 'center', justifyContent: 'center', display: 'flex', borderLeft: '1px solid white'}}>
                            <p>Certifié</p>
                        </button>
                    
                    </div>
                </div>

                <div style={{overflowY: 'scroll', maxHeight: heightObject}}>
                    {liste.map((user, i) =>
                    filtre_organisme === false || filtre_organisme === '' || filtre_organisme == user.id_organisme?
                    <div key={i} style={{display: 'flex'}}>
                        {console.log(filtre_organisme, user.id_organisme)}
                        <button style={{flex: 1, display: 'flex', borderBottom: '1px solid '+blue}}
                        onClick={async () => {
                            // console.log(user)
                            await setUserFocus(user);
                            await setModalVisible(true);
                        }}
                        >
                            <div style={{flex: 0.2, textAlign: 'center', borderLeft: '1px solid '+blue}}>
                                <p>{user.id}</p>
                            </div>
                            <div style={{display: 'flex', flex: 0.5, borderLeft: '1px solid white', borderLeft: '1px solid '+blue}}>
                                <p style={{marginLeft: 5}}>{user.nom?rescueHtml(user.nom):'.'}</p>
                            </div>
                            <div style={{display: 'flex', flex: 0.5, borderLeft: '1px solid white', borderLeft: '1px solid '+blue}}>
                                <p style={{marginLeft: 5}}>{user.prenom?rescueHtml(user.prenom):'.'}</p>
                            </div>
                            <div style={{display: 'flex', flex: 1, borderLeft: '1px solid white', borderLeft: '1px solid '+blue}}>
                                <p style={{marginLeft: 5}}>{user.email?rescueHtml(user.email):'.'}</p>
                            </div>
                            <div style={{display: 'flex', flex: 1, borderLeft: '1px solid white', borderLeft: '1px solid '+blue}}>
                                <p style={{marginLeft: 5}}>[ {user.id_organisme} ] {user.organisme_nom?rescueHtml(user.organisme_nom):'.'}</p>
                            </div>
                            <div style={{flex: 0.2, borderLeft: '1px solid white', borderLeft: '1px solid '+blue, borderRight: '1px solid '+blue, textAlign: 'center'}}>
                                <p>{user.id_type_users === '1'?'SL':user.id_type_users === '2'?'Admin':user.id_type_users === '3'?'Org':'.'}</p>
                            </div>
                            <div style={{flex: 0.2, borderLeft: '1px solid white', borderRight: '1px solid '+blue, textAlign: 'center'}}>
                                {user.token?'.':
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 1}}></div>
                                    <img style={{width: 15, margin: 5}} src={Checked} />
                                    <div style={{flex: 1}}></div>
                                </div>
                                }
                            </div>
                        </button>
                    </div>
                    :null)}

                </div>

            </div>

        </Access>
	);
}


export default Utilisateurs;
