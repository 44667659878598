import React, { useEffect, useState } from 'react';
import Access from "../components/Access";
import Navbar from "../components/Navbar";
import NavbarAdmin from "../components/NavbarAdmin";
import axios from 'axios';
import qs from 'qs';
import {NotificationManager} from 'react-notifications';


const Application = (props) =>
{
    const [aff, setAff] = useState(false)
    const [liste, setListe] = useState([])
    const heightObject = window.innerHeight/1.9

    const purple = '#6571FE'
    const blue = '#069BD3'

    useEffect(() => {
        axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
        .then(async function (res) {
            var tmp = [];
            for(var line in res.data) {
                tmp.push([line, res.data[line]]);
            }
            await setListe(tmp);
            await setAff(!aff);
        })
        .catch((err) => {
            console.log(err);
        })
    }, [])

	return (
        <Access needConnection={true} type_user={[1]}>
            <Navbar />
            <NavbarAdmin />

            <div style={{display: 'flex', margin: 25}}>
                <p>Gestion \</p>
                <p style={{color: purple, marginLeft: 5, fontWeight: 'bold'}}>Application</p>
            </div>


            <div className='shadow-md rounded-md' style={{margin: 50}}>
                <div style={{overflowY: 'scroll'}}>
                    <div style={{display: 'flex', backgroundColor: blue, borderTopLeftRadius: 5, borderTopRightRadius: 5, color: 'white'}}>
                        <div style={{flex: 0.5, textAlign: 'center'}}>
                            <p>Champ</p>
                        </div>
                        
                        <div style={{flex: 1, textAlign: 'center'}}>
                            <p>Valeur</p>
                        </div>
                    </div>
                </div>

                <div style={{overflowY: 'scroll', maxHeight: heightObject}}>
                    {liste.map((line, i) =>
                    <div key={i} style={{display: 'flex'}}>
                        <div style={{flex: 1, display: 'flex', borderBottom: '1px solid '+blue}}>
                            <div style={{flex: 0.5, textAlign: 'right', borderLeft: '1px solid '+blue, marginRight: 5}}>
                                <p>{line[0]}</p>
                            </div>
                            <div style={{display: 'flex', flex: 1, borderLeft: '1px solid white', borderLeft: '1px solid '+blue}}>
                                <input
                                style={{flex: 1, paddingLeft: 5}}
                                placeholder={line[1]}
                                onBlur={async (e) => {
                                    if (e.target.value !== '') {

                                        var param = {
                                            'set_application': true,
                                            'parametre': line[0],
                                            'valeur': e.target.value
                                        }
                                        await axios.post('https://app-slfinance.fr/api/application.php', qs.stringify(param))
                                        .then(async (res) => {
                                            if (res.data['status'] === 1) {
                                                NotificationManager.success(res.data['status_message'], '', 2000);
                                                var tmp = liste;
                                                await tmp.forEach((element, i) => {
                                                    if (element[0] === line[0]) {
                                                        tmp[i][1] = e.target.value;
                                                        e.target.value = '';
                                                    }
                                                });
                                                await setListe(tmp);
                                                await setAff(!aff);
                                            }
                                            else {
                                                NotificationManager.warning(res.data['status_message'], '', 2000);
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err)
                                        })

                                    }
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    )}

                </div>


            </div>

            <div style={{display: 'flex', margin: 25}}>
                <p>Liens utiles :</p>
            </div>

            <div style={{display: 'flex', marginLeft: 50}}>
                <p>- INSEE :</p>
                <a style={{color: 'blue', marginLeft: 5}} href="https://api.insee.fr/catalogue/site/themes/wso2/subthemes/insee/pages/item-info.jag?name=Sirene&version=V3.11&provider=insee">
                https://api.insee.fr
                </a>
            </div>

            <div style={{display: 'flex', marginLeft: 50}}>
                <p>- FISCALITE (REI) :</p>
                <a style={{color: 'blue', marginLeft: 5}} href="https://www.data.gouv.fr/fr/datasets/impots-locaux-fichier-de-recensement-des-elements-dimposition-a-la-fiscalite-directe-locale-rei-3/">
                https://data.gouv.fr
                </a>
            </div>

            <div style={{display: 'flex', marginLeft: 50}}>
                <p>- BALANCES COMPTABLES :</p>
                <a style={{color: 'blue', marginLeft: 5}} href="https://www.data.economie.gouv.fr/explore/dataset/balances-comptables-des-communes-en-2023/api/">
                https://data.economie.gouv.fr
                </a>
            </div>

            <div style={{display: 'flex', marginLeft: 50}}>
                <p>- GITHUB :</p>
                <a style={{color: 'blue', marginLeft: 5}} href="https://github.com/login">
                https://github.com
                </a>
            </div>

        </Access>
	);
}


export default Application;
