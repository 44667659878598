import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import Dialog from "./components/Dialog";
import Gantt from "./components/GanttComponent";
import { addSpace, escapeHtml, rescueHtml } from "./components/Tools";
import {NotificationManager} from 'react-notifications';
import { ReactComponent as Check } from './Img/icon-check.svg';
import { ReactComponent as Add } from './Img/icon-add.svg';
import { ReactComponent as Trash } from './Img/icon-trash.svg';
import { ReactComponent as Bin } from './Img/icon-bin.svg';

/*
    La variable projet contient toutes les infos du projet et est structurée ainsi:
    projet[0] = id
    projet[1] = id_budget
    projet[2] = numero_projet
    projet[3] = nom
    projet[4] = enjeu
    projet[5] = priorite
    projet[6] = priorite_niveau
    projet[7] = pol_pub_rat (politique publique de rattachement)
    projet[8] = referent_elu
    projet[9] = montant
    projet[10] = description
    projet[11] = remarques
    projet[12] = maitre_ouvrage
    projet[13] = partenaire_mobiliser
    projet[14] = service_referent
    projet[15] = technicien_ref_nom
    projet[16] = technicien_ref_fonction
    projet[17] = technicien_ref_telephone
    projet[18] = technicien_ref_mail
    projet[19] = ressources_humaines
    projet[20] = etudes
    projet[21] = outils
    projet[22] = investissements
    projet[23] = incidences_organisationnelle_administrative
    projet[24] = h1
    projet[25] = h2
    projet[26] = h3
*/
const FicheProjet = (props) =>
{
    let id = window.location.pathname.split('/')[4];
    const [projet, setProjet] = useState([])
    const [incidences_financieres, setIncidences_financieres] = useState([]);
    const [echeancier_realisation, setEcheancier_realisation] = useState([]);
    const [pilotage_projet, setPilotage_projet] = useState([]);
    const [annees, setAnnees] = useState([])
    const [tags, setTags] = useState([])
    const [ppr, setPpr] = useState()
    const [aff, setAff] = useState(false)
    const [details, setDetails] = useState(true)
    const [echeancier, setEcheancier] = useState(false)
    const [moyens, setMoyens] = useState(false)
    const [incidences, setIncidences] = useState(false)
    const [pilotage, setPilotage] = useState(false)
    const [gantt, setGantt] = useState(false)
    const [pilotageLineSelected, setPilotageLineSelected] = useState(null)
    const heightObject = window.innerHeight/1.9

    const green = '#59B44A'
    const blue = '#069BD3'
    const purple = '#6571FE'
    const greyChapter = '#DBDBDB'
    
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    
    async function callbackDialog(ret) {
        if (ret === true) {
            await suppressLinePilotage();
        }
        else {
            await setPilotageLineSelected(null)
        }
    }

    async function suppressLinePilotage()
    {
        pilotage_projet.forEach((element, i) => {
            if (element[0] === pilotageLineSelected) {
                delete pilotage_projet[i];
            }
        });
        await setPilotageLineSelected(null)
    }
    

    async function mount() {
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify({
            'getFichesProjets': true,
            'id': id,
            'id_budget': props.user.id_budget
        }))
        .then((result) => {
            result.data[0][3] = rescueHtml(result.data[0][3]);
            setProjet(result.data[0])
        })

        await axios.post('https://app-slfinance.fr/api/pilotage_projet.php', qs.stringify({
            'get': true,
            'id_projet': id,
        }))
        .then((res) => {
            setPilotage_projet(res.data)
        })

        // Recuperation des annees de prospective
        let prosp_an_deb = ''
        let prosp_an_fin = ''
        await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+props.user.id_budget)
        .then((result) => {
            result.data.forEach(element => {
                if (element[2] === 'prosp_an_deb') {
                    prosp_an_deb = parseInt(element[3])
                }
                if (element[2] === 'prosp_an_fin') {
                    prosp_an_fin = parseInt(element[3])
                }
            });
        }).catch((err) => {console.log(err)})
        
        if (prosp_an_deb === '' || prosp_an_fin === '')
        {
            await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
            .then(async function (res) {
                if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                    if (prosp_an_deb === '') {
                        prosp_an_deb = parseInt(res.data.prosp_an_deb)
                    }
                    if (prosp_an_fin === '') {
                        prosp_an_fin = parseInt(res.data.prosp_an_fin)
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        var liste_an = []
        var i = 0
        while (prosp_an_deb <= prosp_an_fin)
        {
            liste_an[i] = parseInt(prosp_an_deb)
            prosp_an_deb++;
            i++;
        }
        await setAnnees(liste_an)

        await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({
            'get': true,
            'annees': liste_an,
            'id_projet': id
        }))
        .then(async(res) => {
            var total = [];
            await res.data.forEach((element, i) => {
                total[i] = 0;
                element.forEach((elem, j) => {
                    if (j > 1) {
                        if (!parseFloat(elem[1])) {
                            elem[1] = 0;
                        }
                        else {
                            elem[1] = parseFloat(elem[1]);
                        }
                        total[i] += (elem[1]);
                    }
                });
            });
            await res.data.forEach((line, k) => {
                res.data[k].push(['Total', total[k]])
            });
            setIncidences_financieres(res.data)
        })

        await axios.post('https://app-slfinance.fr/api/echeancier_realisation.php', qs.stringify({
            'get': true,
            'annees': liste_an,
            'id_projet': id
        }))
        .then((res) => {
            setEcheancier_realisation(res.data)
        })
    }


    // Récuperation de la fiche projet
    useEffect(() => {
        if (props.user === null) {
            props.getUser()
        }
        else {
            mount();
        }
        
    }, [props.user])

    

    function sumChapters(obj)
    {
        obj.forEach((line, i) => {
            line.forEach((val, j) => {
                if (j > 1) {
                    if (line[1] === '') {
                        obj[i][j][1] = 0;
                    }
                }
            });
        });
        obj.forEach((line) => {
            line.forEach((val, j) => {
                if (j > 1) {
                    var cel = 0
                    if (val[1] !== null && val[1] !== '') {
                        cel = parseFloat(val[1])
                    }
                    if (line[1] === "DF") {
                        obj[0][j][1] = (parseFloat(obj[0][j][1]) + cel).toString();
                    }
                    if (line[1] === "RF") {
                        obj[5][j][1] = (parseFloat(obj[5][j][1]) + cel).toString();
                    }
                    if (line[1] === "DI") {
                        obj[10][j][1] = (parseFloat(obj[10][j][1]) + cel).toString();
                    }
                    if (line[1] === "RI") {
                        obj[15][j][1] = (parseFloat(obj[15][j][1]) + cel).toString();
                    }
                }
            });
        });
        setIncidences_financieres(obj)
    }

    async function updateObjetLine(matricule, annee, valeur)
    {
        var tmp = incidences_financieres
        await tmp.forEach(async (line, i) => {
            if (line[0] === matricule) {
                await line.forEach((cel, j) => {
                    if (cel[0] === annee) {
                        tmp[i][j][1] = valeur
                    }
                });
            }
        });
        sumChapters(tmp)
    }
    

    async function valider()
    {
        var ret = true;
        projet[3] = escapeHtml(projet[3]);

        // Requette pour modifier une fiche projet
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify({'updateProjet': true, 'projet': projet}))
        .then(async (result) => {

            if (result.data['status'] === 1) {
                NotificationManager.success(result.data['status_message'], '', 700);

                // Si la fiche projet a pu etre modifiee, alors requette pour modifier Incidences financières
                await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({'update': true, 'id_projet': id, 'incidences_financieres': incidences_financieres}))
                .then((res) => {
                    if (res.data['status'] === 1) {
                        NotificationManager.success(res.data['status_message'], '', 800);
                    }
                    else {
                        ret = false;
                        NotificationManager.warning("La modification du tableau des Incidences financières n'a pas pu se faire", '', 3000);
                    }
                })


                // Si la fiche projet a pu etre modifiee, alors requette pour modifier Pilotage projet
                await axios.post('https://app-slfinance.fr/api/pilotage_projet.php', qs.stringify({'update': true, 'id_projet': id, 'objet': pilotage_projet}))
                .then((res) => {
                    if (res.data['status'] === 1) {
                        NotificationManager.success(res.data['status_message'], '', 800);
                    }
                    else {
                        ret = false;
                        NotificationManager.warning("La modification du tableau du pilotage des projets n'a pas pu se faire", '', 3000);
                    }
                })

                // Si la fiche projet a pu etre modifiee, alors requette pour modifier Echeancier Realisation
                await axios.post('https://app-slfinance.fr/api/echeancier_realisation.php', qs.stringify({'update': true, 'id_projet': id, 'echeancier_realisation': echeancier_realisation}))
                .then((res) => {
                    console.log(res.data)
                    if (res.data['status'] === 1) {
                        NotificationManager.success(res.data['status_message'], '', 800);
                    }
                    else {
                        ret = false;
                        NotificationManager.warning("La modification du tableau des Echeancier de Realisation n'a pas pu se faire", '', 3000);
                    }
                })
            }
            else {
                ret = false;
                NotificationManager.warning("La modification de la fiche projet n'a pas pu se faire", '', 3000);
            }
        })
        return ret;
    }


    async function updatePilotage(cellule, i, value)
    {
        pilotage_projet.forEach((element, j) => {
            if (i === j) {
                pilotage_projet[j][cellule] = value
            }
        });
        await setAff(!aff);
    }


    async function addLinePilotage()
    {
        let date = new Date();
        let yyyy = date.getFullYear();
        let mm = date.getMonth() + 1;
        let dd = date.getDate();
        if (dd < 10) {
            dd = '0'+dd;
        }
        if (mm < 10) {
            mm = '0'+mm;
        }
        
        var tmp = ['tmp_'+pilotage_projet.length, id, dd+'/'+mm+'/'+yyyy, '', '', '', '', ''];
        await pilotage_projet.push(tmp)
        await setAff(!aff);
    }


	return (
        <div>
            <Access needConnection={true} type_user={[0]}>
            <Navbar user={props.user} />

                <Dialog
                    setModalVisible={setDialogModalVisible}
                    modalVisible={dialogModalVisible}
                    callbackDialog={callbackDialog}
                    message={"Confirmez la suppression"}
                />

                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <div style={{flex: 1, display: 'flex'}}>
                            <p>Prospective \ Fiches projets \ Projet n°{projet[2]} :</p>
                            <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>{projet && projet[3]?rescueHtml(projet[3]):''}</p>
                        </div>
                        <div>
                            {props.user?
                            <p style={{flex: 1, textAlign: 'center'}}>{props.user.nom_budget}</p>
                            :null}
                        </div>
                        <div style={{flex: 1}}></div>
                    </div>
                </div>

                <div style={{flex: 1, textAlign: 'center'}}>

                    {projet && projet.length !== 0?
                    <div style={{marginBottom: 100, marginRight: 100, marginLeft: 100}}>
                        <div style={{margin: 30, display: 'flex'}}>
                            <div style={{flex: 1}}></div>
                            <button
                                className='shadow-md p-2 rounded-md'
                                style={details?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                                onClick={() => {
                                    setDetails(true)
                                    setEcheancier(false)
                                    setGantt(false)
                                    setMoyens(false)
                                    setIncidences(false)
                                    setPilotage(false)
                                }}
                            >
                                Données générales
                            </button>

                            <button
                                className='shadow-md p-2 rounded-md'
                                style={echeancier?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                                onClick={() => {
                                    setDetails(false)
                                    setEcheancier(true)
                                    setGantt(false)
                                    setMoyens(false)
                                    setIncidences(false)
                                    setPilotage(false)
                                }}
                            >
                                Échéancier de réalisation
                            </button>

                            <button
                                className='shadow-md p-2 rounded-md'
                                style={gantt?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                                onClick={() => {
                                    setDetails(false)
                                    setEcheancier(false)
                                    setGantt(true)
                                    setMoyens(false)
                                    setIncidences(false)
                                    setPilotage(false)
                                }}
                            >
                                GANTT
                            </button>

                            <button
                                className='shadow-md p-2 rounded-md'
                                style={moyens?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                                onClick={() => {
                                    setDetails(false)
                                    setEcheancier(false)
                                    setGantt(false)
                                    setMoyens(true)
                                    setIncidences(false)
                                    setPilotage(false)
                                }}
                            >
                                Moyens mis en œuvre
                            </button>

                            <button
                                className='shadow-md p-2 rounded-md'
                                style={incidences?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                                onClick={() => {
                                    setDetails(false)
                                    setEcheancier(false)
                                    setGantt(false)
                                    setMoyens(false)
                                    setIncidences(true)
                                    setPilotage(false)
                                }}
                            >
                                Incidences Financières
                            </button>

                            <button
                                className='shadow-md p-2 rounded-md'
                                style={pilotage?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                                onClick={() => {
                                    setDetails(false)
                                    setEcheancier(false)
                                    setGantt(false)
                                    setMoyens(false)
                                    setIncidences(false)
                                    setPilotage(true)
                                }}
                            >
                                Pilotage
                            </button>
                            <div style={{flex: 1}}></div>

                            <button
                                className='shadow-md rounded-md'
                                style={{backgroundColor: green, color: 'white', fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                                onClick={async () => {
                                    if (projet.length !== 0) {
                                        await valider()
                                        .then((res) => {
                                            if (res) {
                                                setTimeout(() => {window.location = window.location.origin + '/saisie/prospective/fichesprojets'}, 1000);
                                            }
                                        })
                                    }
                                }}
                            >
                                <Check width="12" />
                                <p style={{marginLeft: 10}}>Valider</p>
                            </button>

                        </div>

                        {/****************************** Données générales ********************************/}
                        {details?
                        <div>
                            <div style={{backgroundColor: 'white', border: '1px solid '+blue, borderRadius: 2}}>

                                {/* Ligne 1: Intitulé du projet */}
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Intitulé du projet</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <input
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[3] = e.target.value
                                            await setAff(!aff)
                                        }}
                                        value={projet[3] === null?"":rescueHtml(projet[3])}
                                        />
                                        <div style={{display: 'flex', padding: 10, borderLeft: '1px solid '+blue}}>
                                            <p>Projet numéro : </p>
                                            <input
                                            style={{marginLeft: 10, width: 50, textAlign: 'center'}}
                                            onChange={async (e) => {
                                                projet[2] = (e.target.value)
                                                await setAff(!aff)
                                            }}
                                            value={projet[2] === null?"":rescueHtml(projet[2])}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/****/}

                                {/* Ligne 2: Enjeu */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Descriptif des enjeux</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <textarea
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[4] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[4] === null?"":rescueHtml(projet[4])}
                                        />
                                    </div>
                                </div>
                                {/****/}

                                {/* Ligne 3: Priorite */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Priorité</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <input
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[5] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[5] === null?"":rescueHtml(projet[5])}
                                        />
                                        <div style={{padding: 10, borderLeft: '1px solid '+blue}}>
                                            <p>Niveau de priorité : </p>
                                            <select
                                            style={{marginLeft: 10, minWidth: 100, textAlign: 'center', border: '1px solid '+blue, borderRadius: 3}}
                                            value={projet[6]}
                                            onChange={async (e) => {
                                                projet[6] = escapeHtml(e.target.value)
                                                await setAff(!aff)
                                            }}
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                {/****/}

                                {/* Ligne 4: Politique publique de rattachement */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Politique publique de rattachement</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <div style={{flex: 1, textAlign: 'left', padding: 20}}>
                                            <div>
                                                <div style={{display: 'flex'}}>
                                                    <input
                                                    style={{display: 'flex', padding: 5, border: '1px solid '+blue, borderRadius: 5, minWidth: 300}}
                                                    onChange={async(e) => {
                                                        if (e.target.value)
                                                        {
                                                            setPpr(e.target.value)
                                                            await axios.post('https://app-slfinance.fr/api/tags_ppr.php', qs.stringify({
                                                                'getTags': true,
                                                                'id_budget': props.user.id_budget,
                                                                'intitule': escapeHtml(e.target.value)
                                                            }))
                                                            .then(async(result) => {
                                                                await setTags(result.data)
                                                                await setAff(!aff)
                                                            })
                                                        }
                                                        else {
                                                            setTags([])
                                                        }
                                                    }}
                                                    placeholder='Ajouter un tag'
                                                    />

                                                    <button
                                                    style={{margin: 5, color: green}}
                                                    onClick={async() => {
                                                        if (ppr) {
                                                            var isin = 0
                                                            if (!projet[7])
                                                            {
                                                                projet[7] = '';
                                                            }
                                                            await projet[7].split('|').forEach(tag => {
                                                                if (tag === (ppr)) {
                                                                    isin = 1
                                                                }
                                                            });
                                                            if (isin === 0) {
                                                                projet[7] += escapeHtml((ppr))+'|'
                                                                await setAff(!aff)
                                                            }
                                                        }
                                                    }}
                                                    >
                                                        Valider
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                        {projet[7]?
                                        <div style={{flex: 1}}>
                                            {projet[7].split('|').map((tag, index) => 
                                            tag?
                                            <button
                                            key={index}
                                            style={{backgroundColor: blue, borderRadius: 5, margin: 5, paddingLeft: 10, paddingRight: 10}}
                                            onClick={async() => {
                                                var dialog = window.confirm("Veuillez confirmer la suppression");
                                                if (dialog) {
                                                    projet[7] = projet[7].replace(tag+'|', '')
                                                    await setAff(!aff)
                                                }
                                            }}>
                                                <p style={{color: 'white'}}>{rescueHtml(tag)}</p>
                                            </button>
                                            :null
                                            )}
                                        </div>
                                        :null}
                                    </div>
                                </div>
                                {/****/}

                                {/* Ligne 5: Référent élu */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Référent élu</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <input
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[8] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[8] === null?"":rescueHtml(projet[8])}
                                        />
                                    </div>
                                </div>
                                {/****/}

                                {/* Ligne 6: Montant du projet (en € TTC) */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Montant du projet</p>
                                        <p>(en € TTC)</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <input
                                        type="float"
                                        style={{flex: 1, padding: 5}}
                                        onBlur={async (e) => {
                                            if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                                projet[9] = (Math.round(parseFloat(e.target.value) * 100) / 100).toString().replace(',', '.').replace('€', '').replace(' ', '').replace("'", '').replace('"', '')
                                                if (!e.target.value.includes('€')) {
                                                    e.target.value = addSpace((Math.round(parseFloat(e.target.value) * 100) / 100).toString().replace("'", '').replace('"', ''))+'€'
                                                }
                                                await setAff(!aff)
                                            }
                                            e.target.value = ''
                                        }}
                                        placeholder={projet[9] !== null?addSpace(parseFloat(projet[9]))+'€':null}
                                        />
                                    </div>
                                </div>
                                {/****/}

                                {/* Ligne 7: Description de l'action */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue, minHeight: 100}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Description de l'action</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <textarea
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[10] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[10] === null?"":rescueHtml(projet[10])}
                                        />
                                    </div>
                                </div>
                                {/****/}

                                {/* Ligne 8: Questions/ Remarques  */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue, minHeight: 100}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Questions / Remarques</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <textarea
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[11] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[11] === null?"":rescueHtml(projet[11])}
                                        />
                                    </div>
                                </div>
                                {/****/}

                                {/* Ligne 9: Maître d'ouvrage */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Maître d'ouvrage</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <input
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[12] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[12] === null?"":rescueHtml(projet[12])}
                                        />
                                    </div>
                                </div>
                                {/****/}

                                {/* Ligne 10: Partenaires à mobiliser */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Partenaires à mobiliser</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <input
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[13] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[13] === null?"":rescueHtml(projet[13])}
                                        />
                                    </div>
                                </div>
                                {/****/}
                            </div>
                        </div>
                        :null}
                        {/**************************************************************/}
                        

                        {/****************************** Échéancier de réalisation ********************************/}
                        {echeancier?
                        <div>
                            {/* Tableau des Echeancier realisation */}
                            <div style={{display: 'flex', marginTop: 10}}>
                                <div style={{flex: 1}}>
                                    <p>Actions à engager</p>
                                </div>
                                {annees.map((annee, i) => 
                                <div key={i} style={{flex: 0.7}}>
                                    <p>{annee}</p>
                                </div>)}
                            </div>
                            <div style={{flex: 1,  backgroundColor: 'white', border: '1px solid '+blue, borderRadius: 2}}>
                                {echeancier_realisation.length !== 0?
                                echeancier_realisation.map((line, i) =>
                                <div key={i} style={i === 0?{display: 'flex', minHeight: 80}:{display: 'flex', borderTop: '1px solid '+blue, minHeight: 80}}>
                                    {line.map((val, j) =>
                                        j < 1?
                                        <div key={j} style={{flex: 1, display: 'flex', fontSize: 14, minWidth: 50}}>
                                            <textarea
                                            style={{flex: 1, padding: 2, margin: 5, fontSize: 16}}
                                            onChange={async (e) => {
                                                line[1] = e.target.value
                                                await setAff(!aff)
                                            }}
                                            value={rescueHtml(line[1])}
                                            placeholder={"Action "+(i+1)}
                                            />
                                        </div>
                                        :
                                        j >= 2?
                                        <div key={j} style={{flex: 0.7, display: 'flex', fontSize: 14, borderLeft: '1px solid '+blue, minWidth: 50}}>
                                            <textarea
                                            style={{flex: 1, padding: 2, margin: 5, fontSize: 16}}
                                            onChange={async (e) => {
                                                line[j][1] = e.target.value
                                                await setAff(!aff)
                                            }}
                                            value={rescueHtml(val[1])}
                                            />
                                        </div>
                                        :null
                                    )}
                                </div>)
                                :null}
                            </div>
                            {/****/}

                            <div style={{ marginTop: 50, backgroundColor: 'white', border: '1px solid '+blue, borderRadius: 2}}>
                            
                                {/* Ligne 11: Service référent */}
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Service référent</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <input
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[14] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[14] === null?"":rescueHtml(projet[14])}
                                        />
                                    </div>
                                </div>
                                {/****/}

                                {/* Ligne 12: Technicien référent */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Fonction du technicien référent</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <textarea
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[16] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[16] === null?"":rescueHtml(projet[16])}
                                        />

                                        <div style={{flex: 1, padding: 5, borderLeft: '1px solid '+blue}}>
                                            <p>Nom : </p>
                                            <div style={{display: 'flex'}}>
                                                <input
                                                style={{flex: 1, padding: 5, textAlign: 'center'}}
                                                onChange={async (e) => {
                                                    projet[15] = escapeHtml(e.target.value)
                                                    await setAff(!aff)
                                                }}
                                                value={projet[15] === null?"":rescueHtml(projet[15])}
                                                />
                                            </div>
                                        </div>
                                        <div style={{padding: 5, borderLeft: '1px solid '+blue}}>
                                            <p>Téléphone : </p>
                                            <input
                                            style={{padding: 5, textAlign: 'center'}}
                                            onChange={async (e) => {
                                                if (e.target.value) {
                                                    projet[17] = escapeHtml(e.target.value)
                                                    await setAff(!aff)
                                                }
                                            }}
                                            value={projet[17] === null?"":rescueHtml(projet[17])}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/****/}

                                {/* Ligne 13: Technicien référent (Mail) */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Technicien référent (Mail)</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <input
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[18] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[18] === null?"":rescueHtml(projet[18])}
                                        />
                                    </div>
                                </div>
                                {/****/}
                            </div>
                        </div>
                        :null}
                        {/**************************************************************/}
                        

                        {/****************************** GANTT ********************************/}
                        {gantt?
                        <div>
                            <Gantt user={props.user} id_projet={id}/>
                        </div>
                        :null}
                        {/**************************************************************/}


                        {/****************************** Moyens mis en œuvre ********************************/}
                        {moyens?
                        <div>
                            <div style={{ backgroundColor: 'white', border: '1px solid '+blue, borderRadius: 2}}>
                                
                                {/* Ligne 14: Ressources humaines */}
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Ressources humaines</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <textarea
                                        style={{flex: 1, padding: 2, margin: 5, fontSize: 16, minHeight: 100}}
                                        onChange={async (e) => {
                                            projet[19] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[19] === null?"":rescueHtml(projet[19])}
                                        />
                                    </div>
                                </div>
                                {/****/}
                                
                                {/* Ligne 15: Etudes */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Études</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <textarea
                                        style={{flex: 1, padding: 2, margin: 5, fontSize: 16, minHeight: 100}}
                                        onChange={async (e) => {
                                            projet[20] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[20] === null?"":rescueHtml(projet[20])}
                                        />
                                    </div>
                                </div>
                                {/****/}
                                
                                {/* Ligne 16: Outils, matériels */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Outils, matériels</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <textarea
                                        style={{flex: 1, padding: 2, margin: 5, fontSize: 16, minHeight: 100}}
                                        onChange={async (e) => {
                                            projet[21] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[21] === null?"":rescueHtml(projet[21])}
                                        />
                                    </div>
                                </div>
                                {/****/}
                                
                                {/* Ligne 17: Investissements (moyens internes/externes) */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Investissements (moyens internes/externes)</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <textarea
                                        style={{flex: 1, padding: 2, margin: 5, fontSize: 16, minHeight: 100}}
                                        onChange={async (e) => {
                                            projet[22] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[22] === null?"":rescueHtml(projet[22])}
                                        />
                                    </div>
                                </div>
                                {/****/}
                                
                                {/* Ligne 18: Incidences organisationnelle et administrative */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Incidences organisationnelle et administrative</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <textarea
                                        style={{flex: 1, padding: 2, margin: 5, fontSize: 16, minHeight: 100}}
                                        onChange={async (e) => {
                                            projet[23] = escapeHtml(e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[23] === null?"":rescueHtml(projet[23])}
                                        />
                                    </div>
                                </div>
                                {/****/}
                            </div>
                        </div>
                        :null}
                        {/**************************************************************/}



                        {/****************************** Incidences financières ********************************/}
                        {incidences?
                        <div>
                            {/* Tableau des incidences financieres */}
                            <div style={{display: 'flex', marginTop: 20, backgroundColor: blue, color: 'white'}}>
                                <div style={{flex: 1, padding: 5}}>
                                    <p></p>
                                </div>
                                {annees.map((annee, i) =>
                                <div key={i} style={{flex: 0.5, borderLeft: '1px solid white'}}>
                                    <p>{annee}</p>
                                </div>)}
                                <div style={{flex: 0.5, borderLeft: '1px solid white', fontWeight: 'bold', backgroundColor: '#2980b9'}}>
                                    <p>Total</p>
                                </div>
                            </div>
                            <div style={{flex: 1, backgroundColor: 'white', border: '1px solid '+blue, borderRadius: 2}}>
                                {incidences_financieres.length !== 0?
                                incidences_financieres.map((line, i) =>
                                <div key={i} style={i === 0?{display: 'flex'}:line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{display: 'flex', borderTop: '1px solid '+blue}:{display: 'flex', borderTop: '1px solid '+blue}}>
                                    {line.map((val, j) =>
                                        j === 0?
                                        <div key={j} style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{flex: 1, padding: 5, minWidth: 50, backgroundColor: greyChapter}:{flex: 1, padding: 5, minWidth: 50}}>
                                            <p style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{fontWeight: 'bold', fontSize: 15}:{textAlign: 'right', fontSize: 15}}>{val.replace('_', "'")}</p>
                                        </div>
                                        :
                                        j > 1?
                                        <div key={j} style={{flex: 0.5, display: 'flex', fontSize: 14, borderLeft: '1px solid '+blue, minWidth: 50}}>
                                            {val[0] === 'Total' || line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?
                                            <input
                                            type="float"
                                            style={
                                                val[0] === 'Total' && (line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement")?
                                                {flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50, backgroundColor: '#BABABA', fontWeight: 'bold'}
                                                :
                                                val[0] === 'Total'?
                                                {flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50, backgroundColor: greyChapter, fontWeight: 'bold'}
                                                :
                                                {flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50, backgroundColor: greyChapter}
                                            }
                                            value={val[1] === null || val[1] === '' || val[1] === 0?'':addSpace(Math.round(val[1]*100)/100)}
                                            disabled
                                            />
                                            :
                                            <input
                                            type="float"
                                            style={{flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50}}
                                            onBlur={async (e) => {
                                                if (e.target.value === '0' || e.target.value === 0) {
                                                    await updateObjetLine(line[0], val[0], '0')
                                                    await setAff(!aff)
                                                }
                                                else {
                                                    e.target.value = e.target.value.replace(',', '.').replace(/ /g, "")
                                                    if (e.target.value !== '' && (parseFloat(e.target.value)))
                                                    {
                                                        e.target.value = parseFloat(e.target.value)
                                                        await updateObjetLine(line[0], val[0], parseFloat(Math.round(e.target.value * 100) / 100).toString())
                                                        await setAff(!aff)
                                                    }
                                                }
                                                e.target.value = ''
                                            }}
                                            placeholder={val[1] === null || val[1] === '' || val[1] === '0' || val[1] === 0?'':addSpace(Math.round(val[1]*100)/100)}
                                            />
                                            }
                                        </div>:null
                                    )}
                                </div>)
                                :null}
                            </div>
                            {/****/}
                        </div>
                        :null}
                        {/**************************************************************/}




                        {/****************************** Pilotage ********************************/}
                        {pilotage?
                        <div className='shadow-md rounded-md'>
                            <div style={{flex: 1, display: 'flex', backgroundColor: blue, overflowY: 'scroll', borderTopLeftRadius: 5}}>
                                <div style={{flex: 0.15, minWidth: 20, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <Bin width="15" />
                                </div>
                                <div style={{flex: 0.5, borderLeft: '1px solid white', minWidth: 90, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <p style={{color: 'white'}}>Date</p>
                                </div>
                                <div style={{flex: 1, borderLeft: '1px solid white', minWidth: 180, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <p style={{color: 'white'}}>Instance réunie</p>
                                </div>
                                <div style={{flex: 2, borderLeft: '1px solid white', minWidth: 300, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <p style={{color: 'white'}}>Relevé de décisions</p>
                                </div>
                                <div style={{flex: 2, borderLeft: '1px solid white', minWidth: 300, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <p style={{color: 'white'}}>Action suivante</p>
                                </div>
                                <div style={{flex: 0.5, minWidth: 90, borderLeft: '1px solid white', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <p style={{color: 'white'}}>Date limite de l'action</p>
                                </div>
                                <div style={{flex: 1, minWidth: 130, borderLeft: '1px solid white', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <p style={{color: 'white'}}>Responsable de l'action</p>
                                </div>
                            </div>

                            <div style={{flex: 1, height: heightObject, borderBottomRightRadius: 3, borderBottomLeftRadius: 3, overflowY: 'scroll'}}>
                                {pilotage_projet.map((line, i) =>
                                <div key={i} style={{flex: 1, display: 'flex', borderBottom: '1px solid '+blue}}>
                                    
                                    <button
                                    style={{flex: 0.15, minWidth: 20, justifyContent: 'center', alignItems: 'center', display: 'flex'}}
                                    onClick={async () => {
                                        await setDialogModalVisible(true)
                                        await setPilotageLineSelected(line[0])
                                    }}
                                    >
                                        <Trash width="16" />
                                    </button>
                                    
                                    <div style={{flex: 0.5, minWidth: 90, borderLeft: '1px solid '+blue, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <p>{line[2]}</p>
                                    </div>

                                    <input
                                    style={{display: 'flex', flex: 1, minWidth: 180, borderLeft: '1px solid '+blue, alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}
                                    value={line[3]}
                                    onChange={(e) => {
                                        updatePilotage(3, i, e.target.value);
                                    }}
                                    />

                                    <textarea
                                    style={{display: 'flex', flex: 2, minWidth: 300, borderLeft: '1px solid '+blue, alignItems: 'center', justifyContent: 'center'}}
                                    value={line[4]}
                                    onChange={(e) => {
                                        updatePilotage(4, i, e.target.value);
                                    }}
                                    />

                                    <textarea
                                    style={{display: 'flex', flex: 2, minWidth: 300, borderLeft: '1px solid '+blue, alignItems: 'center', justifyContent: 'center'}}
                                    value={line[5]}
                                    onChange={(e) => {
                                        updatePilotage(5, i, e.target.value);
                                    }}
                                    />

                                    <input
                                    style={{display: 'flex', flex: 0.5, minWidth: 90, borderLeft: '1px solid '+blue, alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}
                                    placeholder='jj/mm/aaaa'
                                    value={line[6]}
                                    onChange={(e) => {
                                        updatePilotage(6, i, e.target.value);
                                    }}
                                    />

                                    <input
                                    style={{display: 'flex', flex: 1, minWidth: 130, borderLeft: '1px solid '+blue, alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}
                                    value={line[7]}
                                    onChange={(e) => {
                                        updatePilotage(7, i, e.target.value);
                                    }}
                                    />
                                </div>
                                )}
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 1}}></div>
                                    <div style={{marginTop: 10, marginBottom: 10}}>
                                    <button
                                        className='shadow-md rounded-md'
                                        style={{backgroundColor: purple, color: 'white', fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                                        onClick={async () => {
                                            addLinePilotage();
                                        }}
                                    >
                                        <Add width="12" />
                                        <p style={{marginLeft: 10}}>Ajouter une ligne</p>
                                    </button>
                                    </div>
                                    <div style={{flex: 1}}></div>
                                </div>
                            </div>
                            
                        </div>
                        :null}

                        {/**************************************************************/}
                    </div>
                    :
                    <p style={{margin: 20, fontSize: 20, color: 'orange'}}>Fiche Projet non trouvée</p>}

                </div>
            </Access>
        </div>
	);
}

export default FicheProjet;
